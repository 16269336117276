



























































































import {
  computed, ref, useRoute, useRouter, defineComponent,
} from '@nuxtjs/composition-api';
import { SfHeading, SfButton, SfCallToAction } from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  components: {
  SfHeading,
  SfButton,
  SfCallToAction,
  },
  setup(props, context) {
  context.emit('changeStep', 4);
  const route = useRoute();
  const router = useRouter();

  const companyDetails = ref({
    name: 'contato@finaideia.com.br',
    street: 'Telefone: 11-3331 4445 ramal 215',
    city: 'Whatsapp: 11-93475 2375',
    email: 'Segunda a sexta das 8:00 às 16:30h',
  });
  const orderNumber = computed(() => route.value.query.order ?? '');

  const redirectToContact = () => {
    router.push('/default/contact');
  };

  const goBackToShop = () => {
    router.push('/default');
  };

  return {
    addBasePath,
    companyDetails,
    orderNumber,
    redirectToContact,
    goBackToShop,
  };
  },
});
